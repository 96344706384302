import React from "react";
import * as prismic from "@prismicio/client";

import {
  Box,
  ChakraProvider,
  Container,
  Stack,
  Heading,
  Text,
  Image,
  Flex,
  Grid,
} from "@chakra-ui/react";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import styles from "./PageIntroText.module.css";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";

/**
 * Props for `PageIntroText`.
 */
export type PageIntroTextProps =
  SliceComponentProps<Content.PageIntroTextSlice>;

/**
 * Component for "PageIntroText" Slices.
 */
const PageIntroText = ({ slice }: PageIntroTextProps): JSX.Element => {
  return (
    <Box
      className={styles.pageIntroText}
      background="brand.white"
      width="100%"
      textAlign="center"
    >
      <FadeInWhenVisible>
        <Box
          className={["container", styles.pageIntroText__container].join(" ")}
        >
          <Text textStyle="h4" as="h2" color="brand.dark">
            {prismic.asText(slice.primary.title)}
          </Text>
          <Text
            textStyle="p"
            as="p"
            color="brand.grey"
            maxWidth="1000px"
            textAlign="center"
          >
            {prismic.asText(slice.primary.paragraph)}
          </Text>
          <Text
            textStyle="h5"
            as="h2"
            color="brand.dark"
            textAlign="center"
            marginTop="60px"
          >
            {prismic.asText(slice.primary.subtitle)}
          </Text>
          <Text
            textStyle="p"
            as="p"
            color="brand.grey"
            maxWidth="1000px"
            textAlign="center"
          >
            {prismic.asText(slice.primary.paragraph2)}
          </Text>
          <Text
            textStyle="p"
            as="p"
            color="brand.grey"
            maxWidth="1000px"
            textAlign="center"
            marginBottom="0"
          >
            {prismic.asText(slice.primary.paragraph3)}
          </Text>
        </Box>
      </FadeInWhenVisible>
    </Box>
  );
};

export default PageIntroText;
